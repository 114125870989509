import React from "react";
import NavBar from "../Navigation/NavBar";
import MovieSelection from "../../objects/MovieSelection";
import MovieArchive from "../../objects/MovieArchive";
import { getAllMovieOftheWeekSelections } from "../../apis/SelectionAPI";

// need to get passing of an array or list working or even the entire object of past selections
function VictimList() {
  const archive: MovieArchive = getAllMovieOftheWeekSelections();
  return (
    <>
      <NavBar />
      <p>All our previous selections are here.</p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Title</th>
            <th>Date Selected</th>
            <th>Whose Pick</th>
          </tr>
        </thead>

        <tbody>
          {archive.Films.sort((a, b) => {
            if (a.Viewed < b.Viewed) return 1;
            if (b.Viewed < a.Viewed) return -1;
            return 0;
          }).map(
            (
              { Title, Year, Viewed, Host, Current, Abandoned }: MovieSelection,
              i
            ) => {
              const abandoned = Abandoned;
              if (abandoned) {
                return (
                  <tr key={i}>
                    <td>
                      <del>
                        {Title} ({Year})
                      </del>
                    </td>
                    <td>
                      <del>{Viewed}</del>
                    </td>
                    <td>
                      <del>{Host}</del>
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={i}>
                  <td>
                    {Title} ({Year})
                  </td>
                  <td>{Viewed}</td>
                  <td>{Host}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}

export default VictimList;
