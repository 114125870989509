import React, { useCallback, useState } from "react";
import NavBar from "../Navigation/NavBar";
import MovieDatabase from "../../objects/MovieDatabase";
import Ratings from "../Ratings/Ratings";
import MovieModal from "../MovieModal/MovieModal";
import { Card, Col, Row } from "react-bootstrap";
import { getMovieDatabase } from "../../apis/MovieAPI";

function MovieSearch() {
  const movieDatabase: MovieDatabase = getMovieDatabase();
  const [modalMovie, setModalMovie] = useState(movieDatabase.film_list[0]);
  const [show, setShow] = useState(false);
  const handleClick = useCallback(
    (id: number) => () => {
      setModalMovie(movieDatabase.film_list[id]);
      setShow(true);
    },
    [movieDatabase.film_list]
  );

  const handleClose = () => setShow(false);

  return (
    <>
      <NavBar />
      <Row xs={1} md={3} className="g-4">
        {movieDatabase.film_list.map((movie, i) => (
          <Col key={i}>
            <Card>
              {/* <div className="card" style={{ width: "18rem" }} key={i}> */}
              <img
                className="card-img-top"
                src={`${movie.Poster}`}
                alt={`${movie.Title}`}
              />
              <div className="card-body">
                <h5 className="card-title">
                  {movie.Title} ({movie.Year})
                </h5>
                <p className="card-text">{movie.Plot}</p>
                <div>
                  <Ratings {...movie} />
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClick(i)}
                value={i}
                key={i}
              >
                More Info
              </button>
              <MovieModal
                show={show}
                movie={modalMovie}
                onClose={handleClose}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default MovieSearch;
