import React from "react";
import NavBar from "../Navigation/NavBar";
import Movie from "../../objects/Movie";
import { Image } from "react-bootstrap";
import Ratings from "../Ratings/Ratings";
import MovieModal from "../MovieModal/MovieModal";
import { getMovieOfTheWeek } from "../../apis/SelectionAPI";
import { findMovie } from "../../apis/MovieAPI";

function MovieOfTheWeek() {
  const [show, setShow] = React.useState(false);
  const handleClick = React.useCallback(() => setShow(true), []);
  const handleClose = () => setShow(false);

  const movieSelection = getMovieOfTheWeek();
  const movie: Movie = findMovie(movieSelection.Title, movieSelection.Year);

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="jumbotron">
          <div className="row">
            <div className="col">
              <h1>Movie of the Week</h1>
              <p>
                Here is our curator selected movie for the week. We hope you
                enjoy watching it.
              </p>
              <h2>
                {movie.Title} ({movie.Year})
              </h2>
              <p>{movie.Plot}</p>
              <div className="col-md-8">
                <p>
                  <em>Director:</em> {movie.Director}
                </p>
                <p>
                  <em>Genre:</em> {movie.Genre}
                </p>
                <p>
                  <em>Language:</em> {movie.Language}
                </p>
              </div>
              {/* TODO: Figure out how to just pass props and not have to destructure specifying each attribute. */}
              <Ratings {...movie} />
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClick}
              >
                More Info
              </button>
            </div>
            <div className="col">
              <Image
                className="card-img-top"
                src={`${movie.Poster}`}
                alt={`${movie.Title}`}
              />
            </div>
            <MovieModal show={show} movie={movie} onClose={handleClose} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MovieOfTheWeek;
