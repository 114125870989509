import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import MovieOfTheWeek from "./components/MovieOfTheWeek/MovieOfTheWeek";
import VictimList from "./components/VictimList/VictimList";
import MovieSearch from "./components/MovieSearch/MovieSearch";
import { Container } from "react-bootstrap";
import FilterTest from "./components/FilterTest/FilterTest";

function App() {
  document.body.parentElement?.setAttribute("data-bs-theme", "dark");
  return (
    <Container className="w-75">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <MovieOfTheWeek />
            </>
          }
        />
        <Route
          path="/victim-list"
          element={
            <>
              <VictimList />
            </>
          }
        />
        <Route
          path="/movie-search"
          element={
            <>
              <MovieSearch />
            </>
          }
        />
        <Route
          path="/test-filter"
          element={
            <>
              <FilterTest />
            </>
          }
        />
      </Routes>
    </Container>
  );
}

export default App;
