import MovieSelection from "../objects/MovieSelection";
import moviesOfTheWeek from "../data/movie-of-the-week.json";

export function getMovieOfTheWeek(): MovieSelection {
  const allSelections: MovieSelection[] = moviesOfTheWeek.Films;
  const selection = allSelections.find((f) => f.Current);
  // seems to be the only way to type check an object created from an interface as the class does not exist at runtime
  if (
    selection?.Title &&
    selection.Year &&
    selection.Current &&
    selection.Host &&
    selection.Viewed
  ) {
    return selection;
  } else {
    throw Error("Could not load the movie of the week");
    // return {
    //   Title: "not a movie",
    //   Year: "",
    //   Current: false,
    //   Host: "",
    //   Viewed: "",
    // };
  }
}

export function getAllMovieOftheWeekSelections() {
  return moviesOfTheWeek;
}
