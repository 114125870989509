import Movie from "../objects/Movie";
import zombieMovieData from "../data/zombie-movie-data.json";
import MovieDatabase from "../objects/MovieDatabase";

export interface Filter {
  Field: string;
  Value: string;
  Operator: string;
}

export function findMovie(title: string, year: string): Movie {
  const findCurrent = (m: Movie) => {
    return m.Title === title && m.Year === year;
  };

  const searchResult = zombieMovieData.film_list.filter(findCurrent)[0];

  return searchResult;
}

export function getMovieDatabase(): MovieDatabase {
  return zombieMovieData;
}

export function filterMovies(filters: Filter[]): MovieDatabase {
  let movies = zombieMovieData.film_list;
  for (let i in filters) {
    const filter = filters[i];
    console.log("Applying filter", filter);
    movies = movies.filter((movie) =>
      movie.Title.toLocaleLowerCase().includes(filter.Value.toLowerCase())
    );
  }
  return { film_list: movies };
}
