import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import Movie from "../../objects/Movie";
import Ratings from "../Ratings/Ratings";

function MovieModal(props: {
  show: boolean;
  movie: Movie;
  onClose: () => void;
}) {
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <ModalTitle>
          {props.movie.Title} ({props.movie.Year})
        </ModalTitle>
      </Modal.Header>
      <ModalBody>
        <div className="row">
          <div className="col-md-4">
            <img
              src={`${props.movie.Poster}`}
              alt={`${props.movie.Title}`}
              className="card-img-top"
            />
          </div>
          <div className="col-md-8">
            <p>
              <em>Director:</em> {props.movie.Director}
            </p>
            <p>
              <em>Writer:</em> {props.movie.Writer}
            </p>
            <p>
              <em>Actors:</em> {props.movie.Actors}
            </p>
            <p>
              <em>Genre:</em> {props.movie.Genre}
            </p>
            <p>
              <em>Language:</em> {props.movie.Language}
            </p>
          </div>
          <h3>Plot</h3>
          <p>{props.movie.Plot}</p>
          <Ratings {...props.movie} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={props.onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default MovieModal;
