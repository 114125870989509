import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Filter, filterMovies } from "../../apis/MovieAPI";
import Movie from "../../objects/Movie";

function FilterTest() {
  const [movies, setMovies] = useState<Movie[]>([]);

  return (
    <>
      {/* Formik is a Typescript module specifically for Forms in React with Typescript - might be worth looking into. */}
      <p>Page to test movie filters</p>
      <Form
        onSubmit={(event: React.SyntheticEvent) => {
          event.preventDefault();
          const target = event.target as typeof event.target & {
            title: { value: string };
            rating: { value: string };
          };
          const title = target.title.value;
          const rating = target.rating.value;
          console.log("Arguments:", title, rating);
          let filters: Filter[] = [];
          if (title) {
            const titleFilter: Filter = {
              Field: "Title",
              Value: title,
              Operator: "Like",
            };
            console.log(titleFilter);
            filters.push(titleFilter);
          }
          console.log("Number of filters set", filters.length);
          const result = filterMovies(filters);
          setMovies(result.film_list);
        }}
      >
        <label htmlFor="titleInput">Title</label>
        <input id="titleInput" name="title" />
        <br />
        <label htmlFor="imdbRatingInput">Minimum IMDB Rating</label>
        <input id="imdbRatingInput" name="rating" />
        <br />
        <button>Submit</button>
      </Form>
      {movies.map((movie, i) => (
        <p key={i}>
          {movie.Title} ({movie.Year}) Rating: {movie.imdbRating}
        </p>
      ))}
    </>
  );
}

export default FilterTest;
